.chat_messages_section {
  height: 80%;
  width: 100%;
  box-sizing: border-box;
  background-color: $dark_palette_2;
  padding-top: 24px;

  .chat_messages {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    background-color: $dark_palette_2;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 24px 24px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
      background-color: $dark_palette_4;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: $dark_palette_4;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $dark_palette_6;
      border-radius: 2px;
    }

    .chat_date_field {
      display: flex;
      justify-content: center;
      padding: 6px;

      .chat_date {
        padding: 2px 16px;
        border-radius: 100px;
        background-color: $dark_palette_5;
        color: $white;
        font-size: 14px;
        font-family: mediumFont;
        line-height: 22px;
      }
    }

    .notification_message {
      width: 72%;

      @include minPhone {
        width: 85%;
      }
    }

    .message_field {
      width: 73%;
      box-sizing: border-box;
      padding: 6px 12px;
      background-color: $dark_palette_4;
      display: flex;
      flex-direction: column;
      gap: 2px;
      align-self: flex-start;
      border-radius: 8px;

      @include minPhone {
        width: 85%;
      }

      .sender_name {
        color: $white;
        line-height: 22px;
        font-size: 14px;
        font-family: mediumFont;
      }

      .message {
        color: $placeholder_color;
        font-size: 14px;
        line-height: 22px;
      }

      .message_date {
        display: flex;
        align-items: center;
        gap: 2px;
        align-self: flex-end;

        .date {
          color: $placeholder_color;
          font-size: 12px;
          line-height: 18px;
        }
      }

      .chat_image {
        width: 100%;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 8px;

        @include phone {
          grid-template-columns: repeat(2, 1fr);
        }

        .images_grid_element {
          width: 100%;
          max-width: 100%;
          position: relative;

          .attach_image {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .last_element {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            background-color: #2b2c3bcc;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;
            line-height: 24px;
            font-size: 16px;
          }
        }

        .one_images_grid_element {
          width: 100%;
          height: auto;

          .attach_image {
            width: 260px;
            max-width: 100%;
            height: auto;
          }
        }
      }
    }

    .my_messages {
      align-self: flex-end;
      background-color: $dark_palette_1;
    }

    .admin_message {
      background-color: $dark_palette_5;
    }
  }
}
