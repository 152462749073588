.wallet_steps {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  box-sizing: border-box;

  .two_factor_enabled {
    padding: 16px;
    background-color: $dark_palette_2;
    border-radius: 8px;
    width: fit-content;
    box-sizing: border-box;
    display: flex;
    gap: 8px;
    align-items: center;

    .info_icon {
      width: 24px;
      height: 24px;
    }

    .two_factor_info {
      display: flex;
      align-items: center;
      gap: 4px;

      .info_text {
        color: $white;
        font-size: 14px;
        line-height: 22px;
      }

      .activate_two_factor {
        color: $primary_green;
        text-transform: lowercase;
        cursor: pointer;
      }
    }
  }

  .steps_container {
    padding: 24px;
    width: 100%;
    box-sizing: border-box;
    background-color: $dark_palette_2;
    border-radius: 8px;

    @include ipad {
      padding: 16px;
    }

    .steps_header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .step_info {
        display: flex;
        align-items: center;
        gap: 6px;

        .step_icon {
          width: 24px;
          height: 24px;
        }

        .step_name {
          color: $white;
          font-family: mediumFont;
          font-size: 16px;
          line-height: 24px;
        }
      }

      .arrow_icon {
        width: 24px;
        height: 24px;
        transform: rotate(0);
        transition: all 0.3s;
      }

      .rotate_icon {
        transform: rotate(-180deg);
        transition: all 0.3s;
      }
    }

    .step_content {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding-top: 6px;
      height: 0;
      overflow: hidden;
      transition: all ease 0.5s;

      .step_description {
        color: $white;
        font-size: 14px;
        line-height: 22px;
      }

      .step_form {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .step_fields {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 24px;

          @include ipad {
            grid-template-columns: repeat(1, 1fr);
          }
        }
      }

      .second_step_field {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .verify_code_text {
          color: $white;
          font-family: mediumFont;
          font-size: 16px;
          line-height: 24px;
        }

        .verify_component {
          width: fit-content;
          display: flex;
          flex-direction: column;
          gap: 24px;

          .verify_inputs {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 8px;

            @include phone {
              gap: 8px;
            }

            .verify_line {
              width: 24px;
              height: 2px;
              background-color: $dark_palette_6;
            }

            .verify_input {
              width: 36px;
              height: 36px;
              background-color: transparent;
              border: 1px solid $dark_palette_6;
              border-radius: 6px;
              color: $white;
              text-align: center;
              box-sizing: border-box;

              &:focus {
                outline: none;
              }
            }
          }
        }
      }
    }

    .show_step {
      height: auto;
      transition: all ease 0.5s;
      overflow: unset;
    }
  }
}
