@font-face {
  font-family: lightFont;
  src: url("../public/fonts/SpaceGrotesk-Light.ttf");
}

@font-face {
  font-family: regularFont;
  src: url("../public/fonts/SpaceGrotesk-Regular.ttf");
}

@font-face {
  font-family: mediumFont;
  src: url("../public/fonts/SpaceGrotesk-Medium.ttf");
}

@font-face {
  font-family: semiBoldFont;
  src: url("../public/fonts/SpaceGrotesk-SemiBold.ttf");
}

@font-face {
  font-family: boldFont;
  src: url("../public/fonts/SpaceGrotesk-Bold.ttf");
}

body {
  margin: 0;
  font-family: regularFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #060b11;
}

* {
  margin: 0;
  padding: 0;
}
