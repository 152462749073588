.auth_banner {
  width: 50%;
  height: 100vh;
  background-color: $dark_palette_2;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 0;

  @include phone {
    position: static;
    width: 100%;
    height: 100%;
    right: unset;
    display: none;
  }

  .banner_image {
    width: 600px;
    height: 600px;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;

    @include phone {
      width: 312px;
      height: 312px;
    }
  }
}
