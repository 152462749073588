.faq_page {
  padding: 104px 0 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 60px;

  @include phone {
    gap: 16px;
    padding: 96px 0 56px;
  }

  .faq_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;

    .faq_element {
      display: flex;
      flex-direction: column;
      padding: 24px;
      position: relative;
      border-radius: 12px;
      width: 808px;
      max-width: 100%;
      box-sizing: border-box;

      &::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 12px;
        padding: 1px;
        background: linear-gradient(
          270deg,
          #3bf4de 0%,
          #2690c5 60%,
          #1d63c3 93%
        );
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        pointer-events: none;
        transition: all 0.3s;
      }

      @include phone {
        padding: 16px;
      }

      .faq_answer_section {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 12px;
        cursor: pointer;

        .faq_answer {
          color: $white;
          font-family: mediumFont;
          font-size: 16px;
          line-height: 24px;
          width: 95%;
          box-sizing: border-box;
          word-wrap: break-word;

          @include phone {
            font-size: 14px;
            line-height: 22px;
          }
        }

        .plus_icon_field {
          font-size: 0;
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;

          .plus_icon {
            width: 13px;
            height: 13px;
            transform: rotate(0);
            transition: all ease 0.5s;
          }

          .close_icon {
            transform: rotate(45deg);
            transition: all ease 0.5s;
            width: 14px;
            height: 14px;
          }
        }
      }

      .faq_question {
        color: $placeholder_color;
        font-size: 16px;
        line-height: 24px;
        overflow: hidden;
        height: 0;
        transition: all ease 0.5s;

        @include phone {
          font-size: 14px;
          line-height: 22px;
        }
      }

      .show_question {
        padding-top: 24px;
        height: auto;
        transition: all ease 0.5s;
      }
    }
  }
}
