.images_grid {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: scroll;
  gap: 6px;
  padding: 10px 16px 10px 0;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .grid_element {
    width: 60px;
    height: 60px;
    border-radius: 4px;
    position: relative;

    &:hover {
      .remove_icon {
        display: block;
        transition: all 0.3s;
      }
    }

    .image_grid_field {
      width: 60px;
      height: 60px;
      overflow: hidden;

      .grid_image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
      }
    }

    .remove_icon {
      position: absolute;
      top: -7px;
      right: -6px;
      width: 18px;
      height: 18px;
      display: none;
      transition: all 0.3s;

      .close_icon {
        width: 18px;
        height: 18px;
      }
    }
  }
}
