.profile_page {
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 100%;
  min-height: 900px;
  box-sizing: border-box;
  padding: 98px 34px 34px;

  @include phone {
    flex-direction: column;
    padding: 74px 16px 16px;
  }

  .profile_menu_container {
    background-color: $dark_palette_2;
    padding: 32px 24px;
    width: 260px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: space-between;

    @include phone {
      width: 100%;
    }

    .profile_menu {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;

      .user_info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 4px;
        margin-bottom: 24px;

        .user_avatar_field {
          position: relative;
          font-size: 0;

          .user_avatar {
            width: 48px;
            height: 48px;
          }

          .verify_field {
            background-color: $primary_green_opacity_2;
            padding: 2px 6px;
            border-radius: 100px;
            display: flex;
            flex-direction: row;
            gap: 2px;
            align-items: center;
            position: absolute;
            bottom: -10px;
            left: 50%;
            transform: translateX(-50%);

            .verify_text {
              font-size: 10px;
              line-height: 14px;
              font-family: mediumFont;
              color: $primary_green;
            }

            .verify_icon {
              width: 12px;
              height: 12px;
            }
          }
        }

        .user_name {
          color: $white;
          line-height: 24px;
          font-size: 16px;
          margin-top: 6px;
          text-align: center;
        }

        .user_nickname {
          font-size: 14px;
          color: $placeholder_color;
          line-height: 22px;
        }
      }
    }

    .profile_logout {
      display: flex;
      justify-content: center;
      justify-self: flex-end;
      gap: 4px;

      @include phone {
        justify-content: flex-start;
        padding: 10px;
      }

      .logout_icon {
        cursor: pointer;
      }

      .logout_name {
        color: $placeholder_color;
        font-size: 14px;
        line-height: 22px;
        cursor: pointer;
      }
    }
  }

  .profile_right_side {
    display: flex;
    flex-direction: column;
    gap: 26px;
    width: 100%;
    box-sizing: border-box;

    @include phone {
      gap: 12px;
    }

    .profile_title_field {
      .profile_element_title {
        color: $white;
        font-family: boldFont;
        font-size: 24px;
        line-height: 32px;

        @include phone {
          font-size: 20px;
          line-height: 26px;
        }
      }

      .hide_profile_title {
        @include maxIpad {
          display: none;
        }
      }

      .back_icon {
        width: 24px;
        height: 24px;
        transform: rotate(90deg);
        display: none;
      }

      .show_back_icon {
        @include maxIpad {
          display: block;
        }
      }
    }
  }
}
