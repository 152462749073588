.page_loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 700px;
  width: 100%;
}

.table_loading {
  height: 300px;
}
