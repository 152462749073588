.empty_table_page {
  width: 100%;
  box-sizing: border-box;
  margin-top: 24px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .image_section {
    width: 42px;
    height: 42px;
    font-size: 0;

    .no_filter_icon {
      width: 100%;
      height: 100%;
    }
  }

  .no_result_match {
    margin-top: 12px;
    color: $white;
    font-family: mediumFont;
    font-size: 20px;
    line-height: 28px;
  }

  .no_result_description {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;

    .description {
      color: $placeholder_color;
      font-size: 14px;
      line-height: 22px;
    }
  }
}
