.home_page_container {
  display: flex;
  flex-direction: column;

  .home_trades_table {
    padding: 100px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;

    @include phone {
      padding: 32px 0 0;
    }
  }
}
