.form_container {
  width: 368px;
  padding: 0 16px;
  box-sizing: border-box;

  @include phone {
    padding: 0;
  }
}

.merchant_forms {
  width: 446px !important;
  padding: 0 !important;

  @include phone {
    padding: 0 16px;
    width: 100% !important;
  }
}
