.request_trade_element {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: $dark_palette_2;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;

  .request_section {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @include phone {
      flex-direction: column;
      gap: 16px;
    }

    .request_info_section {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .request_info {
        display: flex;
        gap: 6px;
        align-items: center;

        .star_section {
          display: flex;
          gap: 4px;
          font-size: 0;

          .star_text {
            font-size: 16px;
            color: $white;
            font-family: mediumFont;
            line-height: 24px;
          }
        }

        .user_nickname {
          color: $white;
          font-size: 16px;
          line-height: 24px;
        }

        .verify_icon {
          width: 16px;
          height: 16px;
        }
      }

      .amount_field {
        color: $placeholder_color;

        .request_amount {
          color: $white;
          font-family: mediumFont;
        }

        @include phone {
          font-size: 14px;
          line-height: 22px;
        }
      }

      .request_time {
        display: flex;
        gap: 6px;
        align-items: center;

        .request_time_text {
          color: $placeholder_color;
          font-size: 16px;

          @include phone {
            font-size: 14px;
            line-height: 22px;
          }
        }
      }
    }

    .request_actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;
      padding: 9px 0;

      @include phone {
        padding: 0;
        justify-content: flex-start;
      }
    }
  }
}
