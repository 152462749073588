.mobile_menu_container {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  box-sizing: border-box;
  height: 100dvh;
  backdrop-filter: blur(16px);
  animation: blur_animationEffect 1s;

  @keyframes blur_animationEffect {
    0% {
      backdrop-filter: blur(0);
    }
    100% {
      backdrop-filter: blur(16px);
    }
  }

  @keyframes collapseEffect {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  @keyframes collapseReverseEffect {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  .mobile_menu {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: $dark_palette_2;
    animation: collapseEffect 1s;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    position: relative;

    &::-webkit-scrollbar {
      display: none;
    }

    .action_buttons_field {
      position: sticky;
      bottom: 0;
      left: 0;
      padding: 16px 24px;
      box-sizing: border-box;
      background-color: $dark_palette_opacity_1;
      backdrop-filter: blur(16px);
      width: 100%;

      .merchant_actions_button {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        width: 100%;
        box-sizing: border-box;

        .support_icon {
          width: 38px;
          height: 38px;
        }
      }
    }

    .menu_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      box-sizing: border-box;
      padding: 16px;

      .menu_logo {
        width: 90px;
        height: 40px;

        .mobile_logo {
          width: 100%;
          height: 100%;
        }
      }

      .close_section {
        width: 24px;
        height: 24px;

        .menu_close_icon {
          width: 100%;
          height: 100%;
        }
      }
    }

    .user_section {
      margin-top: 24px;
      padding: 0 24px;

      .guest_actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 8px;
      }

      .menu_user_section {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .user_data {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 12px;

          .avatar_icon {
            width: 32px;
            height: 32px;
          }

          .user_name {
            font-family: mediumFont;
            color: $white;
            line-height: 24px;
            width: 85%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }

    .profile_actions_list {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      gap: 4px;
      box-sizing: border-box;

      .profile_list_element {
        padding: 10px 0;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        transition: all 0.3s;
        border-bottom: 0.5px solid $dark_palette_5;

        &:hover {
          background-color: $dark_palette_2;
          transition: all 0.3s;
        }

        .profile_element_name {
          color: $placeholder_color;
          font-size: 14px;
          line-height: 22px;
          white-space: nowrap;
        }
      }

      .language_element {
        position: relative;
        justify-content: space-between;

        .language_container {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          height: fit-content;
          cursor: pointer;
          position: relative;

          .arrow_icon {
            transform: rotate(0);
            transition: all 1s;
          }

          .rotate_icon {
            transform: rotate(-180deg);
            transition: all 1s;
          }
        }
      }
    }

    .mobile_navbar_section {
      margin-top: 32px;
      padding: 0 24px;
      transition: all 1s;
    }

    .mobile_navbar_opened {
      padding-bottom: 70px;
      transition: all 1s;
    }

    .language_list_container {
      overflow: hidden;

      .language_list {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        gap: 4px;
        box-sizing: border-box;
        padding: 0 16px;
        transform: translateY(-100%);
        opacity: 0;
        transition: all 1s;

        .language_element {
          padding: 10px 0;
          display: flex;
          flex-direction: row;
          gap: 10px;
          align-items: center;
          transition: all 0.3s;
          border-bottom: 0.5px solid $dark_palette_5;

          .language_element_name {
            color: $placeholder_color;
            font-size: 14px;
            line-height: 22px;
            white-space: nowrap;
          }
        }
      }
      .show_language_list {
        transform: translateY(0%);
        opacity: 1;
        transition: all 1s;
      }
    }
  }

  .collapse_menu {
    animation: collapseReverseEffect 1s;
  }
}

@keyframes blurAnimation {
  0% {
    backdrop-filter: blur(16px);
  }
  100% {
    backdrop-filter: blur(0);
  }
}
.blur_animation {
  animation: blurAnimation 1s;
}
