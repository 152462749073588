.text_input_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
  position: relative;

  .input_label {
    font-size: 16px;
    color: $placeholder_color;
    line-height: 24px;
    transition: all 0.3s;
  }

  .passport_label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .example_field {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 4px;
      cursor: pointer;

      &:hover {
        color: $primary_green;
        transition: all 0.3s;

        .hide_hover {
          display: none;
        }

        .show_hover {
          display: block;
        }
      }

      .example_text {
        font-size: 14px;
        line-height: 22px;
        text-decoration: underline;
      }

      .passport_icon {
        width: 20px;
        height: 20px;
      }

      .hide_hover {
        display: block;
      }

      .show_hover {
        display: none;
      }
    }

    .disable_example_field {
      cursor: auto;

      &:hover {
        color: $placeholder_color;

        .hide_hover {
          display: block;
        }

        .show_hover {
          display: none;
        }
      }
    }
  }

  &:has(.text_input:focus) {
    .input_label {
      color: white;
      transition: all 0.3s;
    }
  }

  .input_field {
    width: 100%;
    box-sizing: border-box;
    position: relative;

    .input_adornment {
      position: absolute;
      top: 50%;
      right: 16px;
      width: 24px;
      height: 24px;
      transform: translateY(-50%);
    }

    .password_adornment {
      cursor: pointer;
    }
  }

  .text_input {
    background-color: transparent;
    border: 1px solid $dark_palette_6;
    border-radius: 6px;
    padding: 13px 16px;
    color: $placeholder_color;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
    transition: all 0.3s;

    &:-webkit-autofill {
      transition: background-color 0s 600000s, color 0s 600000s !important;

      &:hover {
        transition: background-color 0s 600000s, color 0s 600000s !important;
      }

      &:focus {
        transition: background-color 0s 600000s, color 0s 600000s !important;
      }

      &:active {
        transition: background-color 0s 600000s, color 0s 600000s !important;
      }
    }

    &::placeholder {
      color: $placeholder_color;
    }

    &:focus {
      outline: none;
      border-color: $white;
      transition: all 0.3s;
      color: $white;
    }
  }

  .text_area_input {
    max-height: 161px;
  }

  .passport_input {
    display: none;
  }

  .passport_label_input {
    width: 100%;
    border: 1px dashed $dark_palette_6;
    border-radius: 6px;
    min-height: 46px;
    background-color: transparent;
    box-sizing: border-box;
    transition: all 0.3s;
    display: block;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    color: $placeholder_color;
    cursor: pointer;

    .file_icon {
      width: 24px;
      height: 24px;
    }
  }

  .passport_label_input_error {
    border: 1px dashed $system_red;
  }

  .drag_active {
    border-color: $primary_green;
  }

  .disabled_text_input {
    color: $placeholder_color;
  }

  .text_input_adornment {
    padding: 13px 42px 13px 16px;
  }

  .input_error {
    border-color: $system_red;

    &:focus {
      border-color: $system_red;
    }
  }

  .error_field {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 4px;
    margin-top: 6px;

    .error_message {
      color: $system_red;
      font-size: 12px;
      line-height: 18px;
    }
  }
}

.disable_image_upload {
  opacity: 0.5;
}

.input_filed_content {
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.select_input {
  display: none;
}

.remove_gap {
  gap: 0;
}

.search_input {
  min-width: 336px;

  @include phone {
    width: 100%;
    min-width: unset;
    box-sizing: border-box;
  }

  .text_input {
    padding: 5px 16px;
    line-height: 24px;
  }
}

.refresh_button {
  background-color: transparent;
  border: 1px solid $dark_palette_6;
  border-radius: 6px;
  padding: 8px 12px;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;

  @include phone {
    height: 40px;
    box-sizing: border-box;
  }
  @keyframes example {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .reload_icon {
    animation-name: example;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(1, 1, 1, 1);
  }
}

.disable_input_container {
  opacity: 0.5;
}

.custom_select_box {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid $dark_palette_6;
  border-radius: 6px;
  padding: 11px 16px;
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  .select_item_name {
    color: $placeholder_color;
    width: 70%;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .select_placeholder {
    color: $placeholder_color;
  }

  .select_adornment {
    width: 24px;
    height: 24px;
    transform: rotate(0);
    transition: all 0.3s;
  }

  .rotate_select_adornment {
    width: 24px;
    height: 24px;
    transform: rotate(-180deg);
    transition: all 0.3s;
    filter: brightness(2);
  }
}

.select_box_open {
  border: 1px solid $white;
}

.select_error {
  border: 1px solid $system_red;
}

.select_dropdown {
  position: absolute;
  box-shadow: 1px 2px 20px 2px #3ef6d10d;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: $dark_palette_1;
  z-index: 10;
  min-width: 285px;
  box-sizing: border-box;
  top: 50px;
  max-height: 306px;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  @include minPhone {
    min-width: unset;
    width: 100%;
  }

  .select_element {
    display: flex;
    align-items: center;
    padding: 8px;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: $dark_palette_2;
    }

    .select_left_section {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      .element_name_field {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;

        .currency_line {
          height: 12px;
          width: 1px;
          background-color: $placeholder_color;
        }
      }

      .element_name {
        color: $placeholder_color;
        font-size: 14px;
        line-height: 22px;
      }

      .select_icon {
        width: 20px;
        height: 20px;
      }
    }
  }

  .selected_element {
    background-color: $dark_palette_2;
  }
}

.multiple_select_dropdown {
  bottom: 50px;
  top: unset;
}

.filter_select {
  width: 100%;
  box-sizing: border-box;
}

.react-datepicker-wrapper {
  width: 100%;

  .date_custom_input {
    width: 100%;
  }
}

.react-datepicker {
  background-color: $black !important;
}

.react-datepicker__header {
  background-color: $black !important;
}

.react-datepicker__current-month {
  color: $placeholder_color !important;
}

.react-datepicker__day-name {
  color: $placeholder_color !important;
}

.react-datepicker__day {
  color: $placeholder_color !important;

  &:hover {
    color: $black !important;
  }
}

.react-datepicker__day--disabled {
  opacity: 0.5 !important;

  &:hover {
    opacity: 0.5 !important;
    color: $placeholder_color !important;
  }
}

.react-datepicker__day--keyboard-selected {
  background-color: $white !important;
  color: $black !important;
}

.react-datepicker__day--in-range {
  background-color: $white !important;
  color: $black !important;
}

.react-datepicker__day--in-selecting-range {
  background-color: $white !important;
  color: $black !important;
}

.react-datepicker__close-icon {
  &::after {
    background-color: $dark_palette_6 !important;
  }
}
