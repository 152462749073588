.info_section {
  display: flex;
  flex-direction: row;
  gap: 4px;

  .text_section {
    color: $placeholder_color;
    font-size: 12px;
    line-height: 18px;
  }

  .info_list {
    margin-left: 15px;

    .list_section {
      display: flex;
      flex-direction: row;
      gap: 4px;

      .list_content {
        display: flex;
        flex-direction: row;
        gap: 4px;

        .list_title {
          font-family: boldFont;
        }
      }
    }
  }
}
