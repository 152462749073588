.footer_container {
  background-color: $dark_palette_opacity_2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  @include ipad {
    align-items: flex-start;
  }

  .background_animation {
    position: absolute;
    right: 0;
    top: 0;
    width: 449px;
    height: 100%;
    z-index: -1;

    .light_background {
      width: 100%;
      height: 100%;
    }

    @include phone {
      width: 50%;
      max-height: 250px;
    }
  }

  .copyright_container {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    padding: 24px;
    box-sizing: border-box;

    @include phone {
      padding: 12px 32px 24px;
    }

    .copyright_line {
      width: 808px;
      height: 1px;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(2, 132, 199, 0.65) 50%,
        rgba(0, 0, 0, 0) 100%
      );

      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      max-width: 100%;

      @include phone {
        width: 311px;
      }
    }

    .copyright_text {
      margin: 0;
      padding: 0;
      color: $placeholder_color;
      line-height: 24px;
    }
  }

  .footer_body {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 63px;
    padding: 44px;

    @include minDesktop {
      gap: 24px;
    }

    @include ipad {
      flex-direction: column;
      padding: 24px 32px;
      justify-content: flex-start;
      gap: 24px;
    }

    .footer_component_element {
      display: flex;
      flex-direction: column;

      .element_title {
        color: $white;
        font-size: 20px;
        font-family: mediumFont;
        line-height: 28px;
        margin: 0 0 16px;
        padding: 0;

        @include phone {
          margin-bottom: 12px;
        }
      }

      .footer_list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 12px;
        min-width: 210px;

        @include minDesktop {
          min-width: 180px;
        }

        @include phone {
          gap: 10px;
        }

        .item {
          font-size: 16px;
          color: $placeholder_color;
          line-height: 24px;
          cursor: pointer;
        }

        .item_with_tag {
          color: $placeholder_color;
          text-decoration: none;
        }
      }

      .social_list {
        flex-direction: row;

        .social_icon {
          width: 32px;
          height: 32px;
        }
      }
    }
  }
}
