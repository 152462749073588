.ongoing_trades_list {
  background-color: $dark_palette_3;
  padding: 16px 16px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 50%;
  box-sizing: border-box;
  height: 810px;
  position: relative;

  @include macDesktop {
    max-height: 615px;
  }

  @include maxIpad {
    width: 100%;
  }

  @include phone {
    max-height: unset;
  }

  .trade_tabs {
    @include phone {
      width: 100%;
    }
  }

  .empty_page_message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;

    .title {
      font-family: boldFont;
      font-size: 24px;
      line-height: 32px;
    }

    .description {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .trades_section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    padding-bottom: 63px;
    box-sizing: border-box;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .trade_item {
      background-color: $dark_palette_2;
      border-radius: 8px;
      padding: 12px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      border: 1px solid $dark_palette_2;

      @include phone {
        gap: 16px;
      }

      .item_header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .item_name_type {
          display: flex;
          align-items: center;
          gap: 12px;

          .trade_id {
            color: $white;
            font-family: mediumFont;
            font-size: 16px;
            line-height: 24px;
          }
        }

        .header_action {
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 0;

          .count_section {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 24px;
            border-radius: 100px;
            min-width: 24px;
            color: white;
            background-color: $system_red;
            font-size: 12px;
            line-height: 18px;
            font-family: mediumFont;
            box-sizing: border-box;
            padding: 2px 8px;

            @include phone {
              height: 22px;
            }
          }

          .close_trade {
            width: 20px;
            height: 20px;

            .close_icon {
              width: 100%;
              height: 100%;
              cursor: pointer;
            }
          }
        }
      }

      .item_body {
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        @include phone {
          grid-template-columns: repeat(1, 1fr);
          gap: 12px;
        }

        .body_element_field {
          display: flex;
          flex-direction: column;

          @include phone {
            flex-direction: row;
            justify-content: space-between;
          }

          .element_title {
            color: $placeholder_color;
            font-size: 14px;
            line-height: 22px;
          }

          .element_info_field {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 0;

            .currency_element_field {
              display: flex;
              align-items: center;
              gap: 6px;

              .currency_line {
                display: block;
                height: 12px;
                width: 1px;
                background-color: $placeholder_color;
              }
            }

            .price_rate_section {
              display: flex;
              align-items: center;
              gap: 4px;

              .element_info {
                line-height: normal;
              }
            }

            .currency_icon {
              width: 24px;
              height: 24px;
              margin-right: 6px;
            }

            .element_info {
              font-size: 16px;
              font-family: mediumFont;
              color: $placeholder_color;
              line-height: 24px;
            }

            .rate_icon {
              font-size: 0;
            }

            .price_icon {
              width: 16px;
              height: 16px;
            }
          }
        }
      }

      .item_footer {
        display: flex;
        justify-content: flex-end;
        width: fit-content;
        align-self: flex-end;

        @include phone {
          justify-content: flex-start;
        }

        .button_icon {
          width: 20px;
          height: 20px;
          margin-right: 4px;
        }
      }
    }

    .selected_trade_id {
      border: 1px solid $primary_green;
    }
  }

  .empty_requests {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .select_trade {
      color: $white;
      font-family: boldFont;
      font-size: 24px;
      line-height: 32px;
    }

    .select_trade_text {
      color: $white;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .trades_request_list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    box-sizing: border-box;
    height: 100%;

    .trade_request_title {
      color: $white;
      font-family: mediumFont;
      font-size: 20px;
      line-height: 28px;

      .trade_id_field {
        color: $primary_green;
      }
    }

    .trades_request {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      padding-bottom: 16px;
      box-sizing: border-box;
      overflow: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      max-height: 90%;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .trade_pagination {
    padding: 12px 32px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    background-color: $dark_palette_3;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border: 1px solid #20212c;
    border-top: none;
    position: absolute;
    bottom: 0;
    left: 0;

    @include phone {
      padding: 12px;
    }
  }
}

.hide_requests_mobile {
  @include maxIpad {
    display: none;
  }
}

.open_chat_container {
  background-color: transparent;
  padding: 0;
  overflow: hidden;
}
