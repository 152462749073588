.main_tab {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 4px;
  border: 1px solid $dark_palette_5;
  border-radius: 6px;
  box-sizing: border-box;

  .tab_element {
    padding: 3px 16px;
    border-radius: 6px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 24px;
    color: $white;
    transition: all 0.5s;
    cursor: pointer;
    width: 100%;
    white-space: nowrap;

    &:hover {
      background-color: $dark_palette_5;
    }
  }

  .active_tab {
    background-color: $primary_green;
    color: $black;
    transition: all 0.5s;

    &:hover {
      background-color: $primary_green_hover;
    }
  }
}
