.label {
  border-radius: 4px;
  backdrop-filter: blur(16px);
  padding: 3px 8px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: space-between;
  width: fit-content;

  .label_name {
    color: $white;
    font-size: 12px;
    line-height: 18px;
    text-transform: capitalize;
  }

  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }

  .circle_green {
    background-color: $system_green;
  }

  .circle_red {
    background-color: $system_red;
  }

  .circle_orange {
    background-color: #fa7f4b;
  }

  .circle_pending {
    background-color: #fa7f4b;
  }

  .circle_accepted {
    background-color: #1bfed1;
  }

  .circle_rejected {
    background-color: #ff554b;
  }

  .circle_closed {
    background-color: #ff554b;
  }

  .circle_completed {
    background-color: #34c759;
  }
}

.label_green {
  background-color: #04b7974d;
}

.label_red {
  background-color: #ff554b66;
}

.label_orange {
  background-color: #fa7f4b33;
}

.label_pending {
  background-color: #fa7f4b33;
}

.label_accepted {
  background-color: #1bfed133;
}

.label_rejected {
  background-color: #ff554b33;
}

.label_closed {
  background-color: #ff554b33;
}

.label_completed {
  background-color: #34c75933;
}
