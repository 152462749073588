.table_filter {
  background-color: transparent;
  border: 1px solid $dark_palette_6;
  border-radius: 6px;
  padding: 6px 12px;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
  transition: all 0.5s;

  &:hover {
    background-color: $dark_palette_5;
    border: 1px solid $dark_palette_5;
  }

  @include phone {
    height: 40px;
    box-sizing: border-box;
  }

  .filter_name {
    color: $primary_green;
    font-size: 14px;
    font-family: mediumFont;
    line-height: 22px;

    @include phone {
      display: none;
    }
  }

  .arrow_green {
    transform: rotate(0deg);
    transition: all 0.5s;
    @include phone {
      display: none;
    }
  }

  .arrow_green_rotate {
    transform: rotate(-180deg);
    transition: all 0.5s;
  }
}

.opened_table_filter {
  border: 1px solid $primary_green;
}
