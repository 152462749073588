.policy_component {
  display: flex;
  flex-direction: column;
  gap: 60px;
  justify-content: center;
  padding: 90px 250px 58px;

  @include minDesktop {
    padding: 90px 100px 58px;
  }

  @include phone {
    padding: 90px 50px 58px;
  }

  @include minPhone {
    padding: 96px 16px 24px;
    gap: 16px;
  }

  .privacy_content {
    * {
      background-color: transparent !important;
      font-family: regularFont !important;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: mediumFont;
      font-size: 20px;
      line-height: 28px;
      color: $white;
      margin: 1em 0px;

      @include minPhone {
        font-size: 16px;
        line-height: 24px;
      }
    }

    p {
      font-size: 16px !important;
      color: $placeholder_color !important;
      line-height: 24px !important;
      margin: 1em 0px !important;
      background-color: transparent !important;
    }

    span {
      font-size: 16px !important;
      color: $placeholder_color !important;
      line-height: 24px !important;
      margin: 1em 0px !important;
      background-color: transparent !important;
    }

    a {
      color: $placeholder_color;
      text-decoration: none;
      background-color: transparent !important;
    }

    ul,
    ol {
      padding: 0 0 0 40px;
      margin: 16px 0;
      background-color: transparent !important;

      li {
        color: $placeholder_color;

        &::marker {
          color: $placeholder_color;
        }
      }
    }
  }
}
