.mobile_table_container {
  display: none;

  @include smallDesktop {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 12px;
    max-height: 730px;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @include phone {
      max-height: 490px;
    }
  }

  .trade_mobile_section {
    border-radius: 8px;
    border: 1px solid $dark_palette_5;
    background-color: $dark_palette_3;
    padding: 12px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .merchant_section {
      display: flex;
      flex-direction: row;
      gap: 16px;
      align-items: center;
      color: $white;
      font-family: mediumFont;

      .merchant_rate {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;

        .star_icon {
          width: 16px;
          height: 16px;
        }
      }

      .merchant_name {
      }
    }

    .underline {
      height: 1px;
      width: 100%;
      box-sizing: border-box;
      background-color: $dark_palette_5;
    }

    .trade_information {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      box-sizing: border-box;

      .trade_elements_title {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
        box-sizing: border-box;

        .element_title_section {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          box-sizing: border-box;

          .element_title {
            color: $white;
            font-family: mediumFont;
            font-size: 14px;
            line-height: 22px;
          }

          .element_name_section {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 4px;

            .currency_field {
              display: flex;
              gap: 6px;
              align-items: center;

              .currency_line {
                width: 1px;
                height: 12px;
                background-color: $dark_palette_6;
                display: block;
              }
            }

            .element_name_field {
              display: flex;
              align-items: center;
            }

            .element_name {
              color: $placeholder_color;
              font-size: 14px;
              line-height: 22px;
            }

            .currency_icon {
              width: 20px;
              height: 20px;
            }

            .amd_currency_icon {
              width: 16px;
              height: 16px;
            }

            .banks_row {
              display: flex;
              justify-content: space-between;
              align-items: center;
              position: relative;
              color: $placeholder_color;
              gap: 5px;

              .banks_names_section {
                max-width: 200px;
                display: block;
              }

              .hidden_banks {
                display: flex;
                align-items: center;
                gap: 6px;
                cursor: pointer;

                .arrow_icon {
                  width: 16px;
                  height: 16px;
                  transform: rotate(0);
                  transition: all 0.3s;
                }

                .rotate_icon {
                  transform: rotate(-180deg);
                  transition: all 0.3s;
                }
              }

              .banks_dropdown {
                position: absolute;
                top: 40px;
                background-color: $dark_palette_1;
                border-radius: 8px;
                padding: 8px;
                display: flex;
                flex-direction: column;
                z-index: 10;
                min-width: 163px;
                max-height: 254px;
                overflow: scroll;
                -ms-overflow-style: none;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                  display: none;
                }

                @include phone {
                  max-height: 160px;
                }

                .bank_dropdown_element {
                  padding: 6px 8px;
                }
              }
            }

            .view_details {
              background-color: transparent;
              border: none;
              color: $primary_green;
              font-size: 14px;
              line-height: 22px;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }
    }

    .buy_sell_button {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}
