.modal_container {
  position: fixed;
  z-index: 10000;
  width: 100vw;
  height: 100dvh;
  overflow: hidden;
  left: 0;
  top: 0;
  backdrop-filter: blur(16px);
  display: flex;
  align-items: safe center;
  justify-content: center;
  background-color: $dark_palette_opacity_5;
}

.two_factor_form {
  max-width: 480px;
  box-sizing: border-box;

  @include minPhone {
    width: calc(100% - 32px);
  }

  .two_factor_modal {
    padding: 32px 32px 48px;
    background-color: $dark_palette_1;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    gap: 24px;
    box-sizing: border-box;
    max-width: 480px;

    @include minPhone {
      width: 100%;
      padding: 24px;
      gap: 20px;
    }

    .modal_header {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .modal_description {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;

        @include phone {
          gap: 10px;
        }

        .close_modal {
          align-self: flex-end;
          cursor: pointer;
        }

        .two_factor_title {
          font-size: 24px;
          font-family: boldFont;
          line-height: 32px;
          color: $white;
          text-align: center;

          @include phone {
            font-size: 20px;
            line-height: 26px;
          }
        }

        .two_factor_desc {
          font-size: 14px;
          font-family: regularFont;
          line-height: 22px;
          color: $placeholder_color;
          text-align: center;
        }
      }

      .qr_code_field {
        width: 100%;
        padding: 24px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $dark_palette_4;
        border-radius: 8px;

        @include phone {
          padding: 14px;
        }

        .qr_code {
          width: 131px;
          height: 131px;

          @include phone {
            width: 120px;
            height: 120px;
          }
        }
      }
    }

    .hand_write_section {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .code_copy_description {
        display: flex;
        flex-direction: column;
        gap: 6px;

        .cant_scan {
          font-size: 16px;
          color: $white;
          font-family: mediumFont;
        }

        .cant_scan_desc {
          font-size: 14px;
          font-family: regularFont;
          line-height: 22px;
          color: $placeholder_color;
        }
      }

      .qr_code_hash_field {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 11px 16px;
        gap: 8px;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid $dark_palette_6;
        border-radius: 6px;

        .hash_code {
          font-size: 16px;
          line-height: 24px;
          color: $placeholder_color;
          width: 88%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .copy_icon {
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
      }
    }

    .verification_field {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;
      box-sizing: border-box;

      .enter_verification {
        line-height: 24px;
        font-family: mediumFont;
        color: $white;
        font-size: 16px;
      }

      .verify_inputs {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        font-size: 16px;

        * {
          font-size: 16px !important;
        }

        @include phone {
          gap: 4px;
        }

        .verify_line {
          width: 92px;
          height: 2px;
          background-color: $dark_palette_6;

          @include phone {
            display: none;
          }
        }

        @keyframes blink_input_opacity_to_prevent_scrolling_when_focus {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }

        .verify_input {
          width: 46px;
          height: 46px;
          background-color: transparent;
          border: 1px solid $dark_palette_6;
          border-radius: 6px;
          color: $white;
          text-align: center;
          box-sizing: border-box;
          font-size: 16px;
          zoom: reset;

          &:focus {
            outline: none;
            zoom: reset;
            font-size: 16px;
          }
        }

        .verify_input_open {
          &:focus {
            animation: blink_input_opacity_to_prevent_scrolling_when_focus 0.01s;
          }
        }
      }
    }

    .button_activate {
      width: 100%;
      box-sizing: border-box;
    }
  }
}

.modal {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: $dark_palette_1;
  box-shadow: 0px 4px 8px 0px #00000014;
  max-width: 400px;
  box-sizing: border-box;
  border-radius: 8px;

  @include minPhone {
    width: calc(100% - 32px);
  }

  .modal_form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .input_with_info {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 4px;
    }

    .request_trade_field {
      display: flex;
      gap: 7px;

      .equal_field {
        color: $placeholder_color;
        transform: translateY(40px);
      }
    }
  }

  .rate_stars_section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 0;

    .stars_section {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
    }
  }

  .trade_tabs {
    width: 100%;
    background-color: $dark_palette_3;
    border-radius: 6px;
    box-sizing: border-box;
  }

  .logout_header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .close_modal {
      align-self: flex-end;
      cursor: pointer;
    }

    .logout_header_element {
      display: flex;
      flex-direction: column;
      gap: 12px;
      align-items: center;

      .logout_image {
        width: 60px;
        height: 60px;
      }

      .logout_title {
        font-family: boldFont;
        font-size: 24px;
        color: $white;
        line-height: 32px;
      }
    }
  }

  .logout_body {
    display: flex;
    align-items: center;
    justify-content: center;

    .logout_description {
      font-size: 14px;
      line-height: 22px;
      color: $placeholder_color;
      text-align: center;
    }
  }

  .logout_actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    gap: 8px;
  }

  .verify_checkbox_field {
    display: flex;
    gap: 4px;
    align-items: center;

    .verify_checkbox_text {
      color: $placeholder_color;
      font-size: 12px;
      line-height: 18px;
    }
  }
}

.passport_modal {
  background-color: $dark_palette_3;
  padding: 8px 8px 17px;
  max-width: 650px;
  gap: 0;

  .images_field {
    padding: 43px 69px 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    @include phone {
      padding: 16px;
    }
  }

  .swiper_image_element {
    width: 496px;
    height: 496px;

    @include phone {
      width: 100%;
      height: 100%;
    }
  }

  .arrow_container {
    display: flex;
    justify-content: center;
    gap: 16px;
    cursor: pointer;
  }

  @include phone {
    max-width: calc(100% - 16px);
  }
}

.thank_you_modal {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: $dark_palette_1;
  box-shadow: 0px 4px 8px 0px #00000014;
  max-width: 400px;
  box-sizing: border-box;
}

.change_password_modal {
  width: 400px;

  @include minPhone {
    width: calc(100% - 32px);
  }
}
