$white: #fff;
$black: #181920;
$placeholder_color: #b2bcce;

// System colors
$system_red: #ff554b;
$system_green: #04b797;
$system_blue: #1890ff;
$system_yellow: #eac33b;
$system_green_hover: #23e6c3;
$system_red_hover: #ff382c;

// Primary colors
$primary_green: #1bfed1;
$primary_green_hover: #04cea6;
$primary_blue: #048ef0;
$primary_white: #fff;
$primary_green_opacity_2: #1bfed133;

// Dark Palette
$dark_palette_1: #060b11;
$dark_palette_2: #14181d;
$dark_palette_3: #1a1e23;
$dark_palette_4: #202429;
$dark_palette_5: #2b2c3b;
$dark_palette_6: #484956;
$dark_palette_7: #d1d1d6;

// Palette with opacity
$dark_palette_opacity_1: #1a1a1a33;
$dark_palette_opacity_2: #38383833;
$dark_palette_opacity_3: #49636066;
$dark_palette_opacity_4: #96414133;
$dark_palette_opacity_5: #88888833;

$background_animated_1: #08ffff99;
$table_border_color: #20212c;

// Toast
$toast_success: #3bf4ca1a;
$toast_error: #ff554b1a;
$toast_info: #1890ff1a;
$toast_warning: #eac33b1a;
