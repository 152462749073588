.home_banner {
  width: 100%;
  box-sizing: border-box;
  position: relative;

  .home_banner_image {
    width: 100%;
    object-fit: cover;

    @include phone {
      height: 100dvh;
    }
  }

  .home_page_content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    box-sizing: border-box;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .home_page_title_section {
      display: flex;
      align-items: center;
      justify-content: center;

      .home_page_title {
        font-size: 100px;
        font-family: mediumFont;
        line-height: 110px;
        color: $white;
        max-width: 900px;

        @include maxIpad {
          font-size: 40px;
          line-height: 50px;
          max-width: 600px;
        }

        @include minPhone {
          font-family: boldFont;
          font-size: 32px;
          line-height: 40px;
          max-width: 300px;
        }
      }
    }

    .home_page_desc_section {
      margin-top: 32px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include minPhone {
        margin-top: 16px;
      }

      .home_page_desc {
        font-size: 16px;
        line-height: 24px;
        color: $white;
        max-width: 471px;

        @include minPhone {
          font-size: 14px;
          line-height: 22px;
        }
      }
    }

    .support_button_field {
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
