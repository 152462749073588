.pagination {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  color: $placeholder_color;

  .per_page_row {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;

    .per_page_text {
      font-size: 14px;
      font-family: mediumFont;
      line-height: 22px;
    }

    .per_page_selector {
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;
      position: relative;

      .per_page_icon {
        width: 16px;
        height: 16px;
        transform: rotate(0);
        transition: all 0.5s;
      }

      .rotate_icon {
        transform: rotate(-180deg);
        transition: all 0.5s;
      }

      .per_page_number {
        width: 20px;
        text-align: end;
      }

      .per_page_dropdown {
        position: absolute;
        background-color: $dark_palette_1;
        box-shadow: 1px 2px 20px 2px #3ef6d10d;
        border-radius: 8px;
        padding: 8px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: 120px;
        box-sizing: border-box;
        z-index: 10;
        right: 0;
        top: 20px;

        .per_page_element {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding: 10px 8px;
          box-sizing: border-box;
          transition: all 0.3s;

          &:hover {
            background-color: $dark_palette_2;
            transition: all 0.3s;
          }
        }
      }

      .per_page_dropdown_up {
        top: -200px;
      }
    }
  }

  .page_selector {
    display: flex;
    gap: 10px;
    align-items: center;

    .selected_page {
      width: 42px;
      text-align: center;
    }

    .previous_icon {
      transform: rotate(90deg);
      width: 16px;
      height: 16px;
      cursor: pointer;
    }

    .next_icon {
      transform: rotate(-90deg);
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }
}
