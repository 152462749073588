.messages_container {
  position: fixed;
  z-index: 99999;
  top: 70px;
  right: 50px;
  backdrop-filter: blur(16px);

  @include phone {
    right: 12px;
    top: 84px;
  }

  .toast_container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 16px;
    border-radius: 8px;

    .toast_info {
      display: flex;
      flex-direction: column;
      gap: 2px;

      .toast_type {
        color: $white;
        line-height: 24px;
        font-family: mediumFont;
      }

      .toast_message {
        line-height: 22px;
        font-size: 14px;
        color: $white;
      }
    }

    .close_toast {
      .close_icon {
        cursor: pointer;

        @include phone {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .success {
    border: 1px solid $system_green;
    background-color: $toast_success;
  }

  .error {
    border: 1px solid $system_red;
    background-color: $toast_error;
  }

  .info {
    border: 1px solid $system_blue;
    background-color: $toast_info;
  }

  .warning {
    border: 1px solid $system_yellow;
    background-color: $toast_warning;
  }
}
