.sign_up_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  min-height: 100dvh;
  box-sizing: border-box;
  padding-bottom: 24px;
  background: radial-gradient(circle at bottom left, #005f61, transparent 60%),
    radial-gradient(circle at top right, #0c4a6e, transparent 60%), #000;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

  @include phone {
    padding: 72px 16px 0;
  }

  .sign_up_form {
    display: flex;
    flex-direction: row;
    align-items: safe center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    box-sizing: border-box;
    margin: 96px 0 40px;
    background-color: $dark_palette_2;
    border-radius: 8px;
    padding: 32px;

    @include phone {
      max-width: 100%;
      padding: 24px;
      margin-top: 34px;
    }

    .sign_up_content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .form_title {
        color: $white;
        font-size: 24px;
        line-height: 32px;
        font-family: boldFont;
        margin-bottom: 32px;
      }

      .form_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        width: 100%;

        .image_input {
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 4px;
        }

        .form_element {
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 16px;

          .forgot_password_btn {
            width: fit-content;
            background-color: transparent;
            border: none;
            color: $placeholder_color;
            font-size: 14px;
            line-height: 22px;
            cursor: pointer;
            text-decoration: underline;
            font-family: regularFont;
            transition: all 0.3s;

            &:hover {
              color: $primary_green;
              transition: all 0.3s;
            }
          }
        }

        .sign_up_button {
          margin-top: 16px;
          width: 100%;
        }
      }

      .sign_in_section {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        margin-top: 24px;

        .have_account_text {
          font-size: 14px;
          line-height: 22px;
          color: $placeholder_color;
        }

        .sign_in_link {
          color: $primary_green;
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }
}
