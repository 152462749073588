.main_button {
  padding: 8px 20px;
  font-size: 14px;
  line-height: 22px;
  border-radius: 100px;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .cancel_arrow_icon {
    margin-right: 4px;
    transform: rotate(90deg);
  }
}

.log_in_button {
  background-color: transparent;
  color: $white;
  border: none;
  border-radius: 100px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 100px;
    padding: 1px;
    background: linear-gradient(270deg, #3bf4de 0%, #2690c5 60%, #1d63c3 93%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    transition: all 0.3s;
  }

  &:hover:not(:disabled) {
    &::before {
      background: linear-gradient(270deg, #2dbe9d 0%, #1552a5 93%);
      transition: all 0.3s;
    }
  }

  &:disabled {
    opacity: 0.5;
  }
}

.custom_button {
  background-color: $primary_green;
  color: $black;
  transition: all 0.3s;

  &:hover:not(:disabled) {
    background-color: $primary_green_hover;
    transition: all 0.3s;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.buy_button {
  background-color: $system_green;
  color: $white;
  transition: all 0.3s;
  padding: 5px 20px;

  &:hover:not(:disabled) {
    background-color: $system_green_hover;
    transition: all 0.3s;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.disable_state {
  opacity: 0.5;
}

.red_button {
  background-color: $system_red;
  color: $white;
  transition: all 0.3s;
  padding: 5px 20px;

  &:hover:not(:disabled) {
    background-color: $system_red_hover;
    transition: all 0.3s;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.capitalize {
  text-transform: capitalize;
}

.submit_button {
  width: 151px;
}

.width_100 {
  width: 100%;
}

.create_trade_btn {
  width: 269px;
}

.width_fit {
  width: fit-content;
}

.go_to_chat_button {
  width: 212px;
  max-width: 80%;
}

.inactive_button {
  background-color: transparent;
  color: $system_red;
  transition: all 0.3s;
  border: 1px solid $system_red;

  &:hover:not(:disabled) {
    transition: all 0.3s;
    border: 1px solid $system_red_hover;
    color: $system_red_hover;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.active_button {
  background-color: transparent;
  color: $system_green;
  transition: all 0.3s;
  border: 1px solid $system_green;

  &:hover:not(:disabled) {
    transition: all 0.3s;
    border: 1px solid $system_green_hover;
    color: $system_green_hover;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.chat_button {
  padding: 6px 20px;
}
