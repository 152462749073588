.table_filters {
  display: grid;
  grid-template-areas: "currency currency min_amount max_amount bank bank";
  grid-template-columns: repeat(6, 1fr);
  gap: 12px;
  width: 100%;
  box-sizing: border-box;

  @include maxIpad {
    grid-template-areas: "currency currency" "min_amount max_amount" "bank bank";
    grid-template-columns: repeat(2, 1fr);
    column-gap: 6px;
    row-gap: 12px;
  }

  .currency_filter {
    grid-area: currency;
  }

  .min_amount_filter {
    grid-area: min_amount;
  }

  .max_amount_filter {
    grid-area: max_amount;
  }

  .bank_filter {
    grid-area: bank;
  }
}

.filters_container {
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;

  .filters_open {
    transition: all 0.5s;
    opacity: 1;
    height: 46.5px;
  }

  .home_filters_open {
    @include maxIpad {
      height: 162.5px;
    }
  }

  .balance_filters_open {
    @include phone {
      height: 108px;
    }
  }

  .transaction_filters_open {
    @include phone {
      height: 162.5px;
    }
  }

  .filters_close {
    transition: all 0.5s;
    opacity: 0;
    height: 0;
  }

  .transaction_table_filter {
    display: grid;
    grid-template-areas: "nickname status date";
    grid-template-columns: repeat(3, 1fr);

    @include phone {
      grid-template-columns: repeat(1, 1fr);
      grid-template-areas: "nickname" "status" "date";
    }

    .nickname_filter {
      grid-area: nickname;
    }

    .status_filter {
      grid-area: status;
    }

    .date_filter {
      grid-area: date;
    }
  }

  .balance_table_filter {
    display: grid;
    grid-template-areas: "source date nickname";
    grid-template-columns: repeat(3, 1fr);

    @include phone {
      grid-template-columns: repeat(1, 1fr);
      grid-template-areas: "source" "date" "nickname";
    }

    .source_filter {
      grid-area: source;
    }

    .date_filter {
      grid-area: date;
    }

    .nickname_filter {
      grid-area: nickname;
    }
  }
}

.filter_container_show {
  overflow: unset;
}
