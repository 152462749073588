.contact_us_container {
  padding: 100px;
  box-sizing: border-box;
  width: 100%;

  @include phone {
    padding: 0 16px 40px;
  }

  .contact_us_content {
    width: 100%;
    border: 1px solid $dark_palette_5;
    border-radius: 12px;
    padding: 88px 141px;
    box-sizing: border-box;

    background-image: url("../images/contact_bg.webp");
    background-repeat: no-repeat;
    background-size: cover;

    @include ipad {
      background-size: contain;
      padding: 29px 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
    }

    .contact_us_form {
      display: flex;
      flex-direction: column;
      max-width: 546px;
      gap: 40px;

      @include ipad {
        width: 100%;
        box-sizing: border-box;
      }

      .form_section {
        display: flex;
        flex-direction: column;
        gap: 24px;
        justify-content: center;

        @include phone {
          gap: 16px;
        }
      }
    }
  }
}
