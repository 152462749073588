.page_title_section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 32px;

  @include phone {
    gap: 16px;
  }

  .page_title {
    font-family: boldFont;
    font-size: 46px;
    line-height: 52px;
    color: $white;

    @include phone {
      font-size: 24px;
      line-height: 32px;
    }
  }
  .underline {
    width: 808px;
    height: 2px;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 20%,
      rgba(2, 132, 199, 0.65) 50%,
      rgba(0, 0, 0, 0) 80%
    );
    max-width: 100%;

    @include phone {
      width: 327px;
      height: 1px;
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(2, 132, 199, 0.65) 50%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }
}
