.checkbox_container {
  .checkbox_label {
    width: 16px;
    height: 16px;
    border: 1px solid $dark_palette_6;
    border-radius: 2px;
    display: block;
    box-sizing: border-box;
    cursor: pointer;
  }

  .checked_checkbox_label {
    background-color: $primary_green;
    border: 1px solid $primary_green;
    background-image: url("../images/inputs/vector.svg");
    background-repeat: no-repeat;
    background-position: center;
  }

  .checkbox_input {
    display: none;
  }
}

.agreement_policy {
  display: flex;
  flex-direction: row;
  gap: 4px;
  width: 100%;
  align-items: center;

  .terms_policy_text {
    font-size: 12px;
    color: $placeholder_color;
    line-height: 18px;

    .clickable_policy {
      color: $placeholder_color;
      cursor: pointer;
    }
  }
}
