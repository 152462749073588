.request_timer {
  border-radius: 4px;
  background-color: #54606b66;
  padding: 2px 6px;
  gap: 1px;
  font-size: 0;
  display: flex;
  align-items: center;
  width: 85px;
  box-sizing: border-box;

  .timer_icon {
    width: 18px;
    height: 18px;
  }

  .timer {
    .timer_filed {
      color: $placeholder_color;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.14;
    }
  }
}

.chat_timer {
  background-color: $dark_palette_opacity_4;

  .timer {
    .timer_filed {
      color: $system_red;
    }
  }
}
