.ongoing_trades {
  width: 100%;
  box-sizing: border-box;
  padding: 98px 34px 34px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include phone {
    padding: 96px 16px 16px;
    gap: 12px;
  }

  .trades_title_section {
    .trades_title {
      font-family: boldFont;
      font-size: 24px;
      color: $white;
      line-height: 32px;
      display: block;

      @include phone {
        font-size: 20px;
        line-height: 26px;
      }
    }

    .hide_trades_title {
      @include maxIpad {
        display: none;
      }
    }

    .arrow_back {
      width: 24px;
      height: 24px;
      transform: rotate(90deg);
      display: none;
    }

    .show_back_icon {
      @include maxIpad {
        display: block;
      }
    }
  }

  .trades_container {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    gap: 12px;

    @include maxIpad {
      flex-direction: column;
    }
  }
}
