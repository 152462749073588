.language_dropdown {
  background-color: $dark_palette_1;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  box-shadow: 1px 2px 20px 2px #ffffff0d;
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);

  .language_element {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 8px;
    border-radius: 4px;
    transition: all 0.3s;

    &:hover {
      background-color: $dark_palette_2;
      transition: all 0.3s;
    }

    .country_name {
      padding: 0;
      margin: 0;
      color: $placeholder_color;
      font-size: 14px;
      line-height: 22px;
    }
  }
}

.profile_dropdown {
  background-color: $dark_palette_1;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  box-shadow: 1px 2px 20px 2px #ffffff0d;
  position: absolute;
  top: 46px;
  right: 0;

  .profile_list {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 4px;
    box-sizing: border-box;

    @include phone {
      display: none;
    }

    .list_element {
      padding: 8px;
      border-radius: 4px;
      background-color: transparent;
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      transition: all 0.3s;

      &:hover {
        background-color: $dark_palette_2;
        transition: all 0.3s;
      }

      .element_name {
        color: $placeholder_color;
        font-size: 14px;
        line-height: 22px;
        white-space: nowrap;
      }
    }

    .list_element_line {
      width: 100%;
      height: 1px;
      background-color: $dark_palette_5;
    }
  }
}
