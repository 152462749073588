.home_about_us {
  width: calc(100% - 200px);
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: $dark_palette_2;
  margin: 80px 100px;
  border-radius: 12px;
  padding: 72px 0 72px 70px;

  @include smallDesktop {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  @include maxIpad {
    padding: 46px 16px 256px;
    margin: 32px 16px;
    width: calc(100% - 32px);
  }

  @include minPhone {
    padding-bottom: 116px;
  }

  .about_us_content {
    width: 50%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-right: 75px;

    @include smallDesktop {
      width: 100%;
      padding-right: 0;
    }

    @include maxIpad {
      gap: 12px;
    }

    .about_title {
      font-family: boldFont;
      font-size: 46px;
      line-height: 52px;
      color: $white;

      @include maxIpad {
        font-size: 24px;
        line-height: 32px;
      }
    }

    .about_description {
      color: $placeholder_color;
      font-size: 16px;
      line-height: 24px;

      @include maxIpad {
        font-size: 14px;
        line-height: 22px;
      }
    }

    .statistics_section {
      display: flex;
      gap: 20px;
      align-items: center;
      margin-top: 20px;

      @include maxIpad {
        margin-top: 12px;
        gap: 16px;
      }

      .statistic_element {
        display: flex;
        flex-direction: column;

        .statistic_count {
          font-size: 46px;
          color: $primary_green;
          font-family: boldFont;
          line-height: 52px;

          @include maxIpad {
            font-size: 32px;
            line-height: 40px;
          }

          .sup_count {
            line-height: normal;
            font-size: 26px;
          }
        }

        .statistic_text {
          font-size: 16px;
          color: $primary_green;
          line-height: 24px;

          @include maxIpad {
            font-size: 14px;
            line-height: 16px;
          }
        }
      }

      .line_between {
        width: 1px;
        height: 65px;
        background: rgb(0, 0, 0);
        background: linear-gradient(
          0,
          rgba(0, 0, 0, 0) 20%,
          rgba(2, 132, 199, 0.65) 50%,
          rgba(0, 0, 0, 0) 80%
        );
        max-width: 100%;
      }
    }
  }

  .currencies_section {
    width: 50%;
    display: flex;
    position: relative;
    justify-content: center;

    @include smallDesktop {
      width: 100%;
    }

    .about_us_image {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      max-width: 100%;
    }

    .currencies_table {
      display: flex;
      flex-direction: column;
      border-radius: 12px;
      border: 1px solid;
      border: 1px solid;
      position: relative;
      background-color: $dark_palette_3;
      z-index: 10;
      height: fit-content;

      @include phone {
        max-width: 100%;
        box-sizing: border-box;
      }

      &::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 12px;
        padding: 1px;
        background: linear-gradient(
          270deg,
          #3bf4de 0%,
          #2690c5 60%,
          #1d63c3 93%
        );
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        pointer-events: none;
        transition: all 0.3s;
      }

      .currencies_list {
        list-style-type: none;
        padding: 0;
        margin: 0;

        .currency_element {
          padding: 10px 16px;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          align-items: center;
          min-width: 460px;
          box-sizing: border-box;
          border-bottom: 1px solid $dark_palette_5;

          &:last-child {
            border-bottom: none;
          }

          @include phone {
            padding: 6px 10px;
            min-width: unset;
          }

          .currency_info {
            display: flex;
            flex-direction: row;
            gap: 10px;

            .currency_icon {
              width: 32px;
              height: 32px;

              @include phone {
                width: 26px;
                height: 26px;
              }
            }

            .currency_name_field {
              display: flex;
              flex-direction: row;
              gap: 8px;
              align-items: center;

              .name {
                font-size: 16px;
                line-height: 24px;
                color: $placeholder_color;

                @include phone {
                  font-size: 12px;
                  line-height: 18px;
                }
              }

              .line {
                height: 12px;
                width: 1px;
                background-color: $dark_palette_6;
                display: block;
              }
            }
          }

          .currency_coast {
            text-align: end;
            color: $white;
            font-size: 16px;
            line-height: 24px;

            @include phone {
              font-size: 12px;
              line-height: 18px;
              text-align: end;
            }
          }

          .currency_percent {
            font-size: 16px;
            line-height: 24px;
            color: $white;
            text-align: end;

            @include phone {
              font-size: 12px;
              line-height: 18px;
            }
          }

          .currency_percent_green {
            color: $system_green;
          }

          .currency_percent_red {
            color: $system_red;
          }
        }
      }
    }
  }
}
