@mixin macDesktop {
  @media (max-width: 1440px) {
    @content;
  }
}

@mixin minDesktop {
  @media (max-width: 1280px) {
    @content;
  }
}

@mixin smallDesktop {
  @media (max-width: 1200px) {
    @content;
  }
}

@mixin maxIpad {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin ipad {
  @media (max-width: 920px) {
    @content;
  }
}

@mixin phone {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin minPhone {
  @media (max-width: 480px) {
    @content;
  }
}
