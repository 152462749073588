.header_container {
  background-color: $dark_palette_opacity_1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 12px 40px;
  z-index: 999;
  backdrop-filter: blur(16px);

  @include phone {
    padding: 17px;
  }

  .header_left_side {
    display: flex;
    flex-direction: row;
    gap: 24px;

    .logo_container {
      width: 90px;
      height: 40px;

      .desktop_logo {
        width: 100%;
        height: 100%;
      }
    }

    .menu_container {
      display: flex;
      align-items: center;
      justify-content: center;

      .menu_list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        gap: 48px;

        .list_item {
          color: $white;
          font-size: 16px;
          line-height: 24px;
          cursor: pointer;
          position: relative;
          transition: all 0.3s;

          &:hover {
            color: $primary_green_hover;
            transition: all 0.3s;
          }

          .active_dot {
            width: 4px;
            height: 4px;
            background-color: $primary_green;
            border-radius: 50%;
            position: absolute;
            top: -12px;
            left: 50%;
            transform: translateX(-50%);
          }
        }

        .active_item {
          color: $primary_green;
        }
      }

      @include phone {
        display: none;
      }
    }
  }

  .desktop_header_actions {
    display: block;

    @include phone {
      display: none;
    }
  }

  .header_right_side {
    display: flex;
    flex-direction: row;
    gap: 12px;
    justify-content: center;
    align-items: center;

    .wallet_element {
      &:hover {
        padding: 4px 8px;
        border-radius: 8px;
        background-color: $dark_palette_5;
      }
    }

    .header_element {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: fit-content;
      cursor: pointer;
      position: relative;

      .arrow_icon {
        transform: rotate(0);
        transition: all 0.5s;
      }

      .rotate_icon {
        transform: rotate(-180deg);
        transition: all 0.5s;
      }

      .avatar_icon {
        width: 44px;
        height: 44px;
      }

      .element_name {
        font-size: 14px;
        line-height: 22px;
        color: $white;
      }
    }

    .header_my_wallet {
      transition: all 0.5;

      &:hover {
        background-color: $dark_palette_5;
        transition: all 0.5;
        border-radius: 4px;
      }
    }

    .gap_4 {
      gap: 4px;
      padding: 4px 8px;
    }

    .support_icon {
      width: 32px;
      height: 32px;
    }

    .mobile_menu_button {
      border: none;
      background-color: transparent;

      .hamburger_menu {
        width: 24px;
        height: 24px;
      }
    }
  }

  .mobile_header_actions {
    display: none;

    @include phone {
      display: block;
    }
  }
}
