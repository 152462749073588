.trade_notification {
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 16px;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  position: relative;

  .notification_icon_field {
    font-size: 0;

    .notification_icon {
      width: 24px;
      height: 24px;
    }
  }

  .notification_field {
    display: flex;
    flex-direction: column;
    gap: 2px;
    color: $white;
    width: 100%;

    .notification_type {
      font-family: mediumFont;
      font-size: 14px;
      line-height: 24px;
    }

    .notification_message {
      font-size: 14px;
      line-height: 22px;
    }
  }

  .notification_date {
    position: absolute;
    bottom: 6px;
    right: 12px;

    .date {
      color: $placeholder_color;
      font-size: 12px;
      line-height: 18px;
    }
  }
}

.completed_trade_notification {
  background-color: $toast_success;
}

.adminCompleted_trade_notification {
  background-color: $toast_success;
}

.rejected_trade_notification {
  background-color: $toast_error;
}

.adminRejected_trade_notification {
  background-color: $toast_error;
}

.arbitrage_trade_notification {
  background-color: $toast_info;
}
