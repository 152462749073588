.wallet_container {
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 100%;
  box-sizing: border-box;

  @include maxIpad {
    flex-direction: column;
  }

  .wallet_currencies {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    box-sizing: border-box;

    @include maxIpad {
      width: 100%;
    }

    .currency_element {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 16px;
      border-radius: 8px;
      background-color: $dark_palette_3;
      border: 1px solid;
      width: 100%;
      box-sizing: border-box;

      .currency_info {
        display: flex;
        align-items: center;
        gap: 6px;

        .currency_icon {
          width: 40px;
          height: 40px;

          @include maxIpad {
            width: 32px;
            height: 32px;
          }
        }

        .currency_name_field {
          display: flex;
          flex-direction: row;
          gap: 6px;
          align-items: center;

          .currency_line {
            height: 12px;
            width: 1px;
            background-color: $placeholder_color;
          }

          .currency_name {
            color: $white;
            font-family: mediumFont;
            font-size: 16px;
            line-height: 24px;
          }
        }
      }

      .about_currency {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .element_info {
          display: flex;
          flex-direction: column;

          .title {
            color: $placeholder_color;
            font-size: 14px;
            line-height: 22px;
          }

          .quantity_amount {
            color: $placeholder_color;
            font-size: 20px;
            font-family: mediumFont;

            @include maxIpad {
              font-size: 16px;
            }
          }

          .amount_element {
            color: $primary_green;
          }
        }

        .right_element_info {
          align-items: flex-end;
        }
      }
    }

    .selected_currency_element {
      border: 1px solid $primary_green;
    }
  }

  .hide_wallet_currencies {
    @include maxIpad {
      display: none;
    }
  }

  .currency_info_field {
    width: 50%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @include maxIpad {
      width: 100%;
    }

    .crypto_info_field {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;
      box-sizing: border-box;

      .crypto_info {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .about_crypto {
          display: flex;
          flex-direction: row;
          gap: 12px;
          align-items: center;

          .crypto_icon {
            width: 60px;
            height: 60px;
          }

          .crypto_name {
            display: flex;
            flex-direction: column;
            gap: 6px;

            .crypto_long_name {
              font-size: 24px;
              color: $white;
              line-height: 32px;
              font-family: boldFont;
            }

            .crypto_long_name_short {
              font-size: 14px;
              line-height: 22px;
              color: $placeholder_color;
            }
          }
        }
        .crypto_message {
          color: $placeholder_color;
          font-size: 14px;
          line-height: 22px;
        }
      }

      .qr_code_field {
        padding: 34px;
        background-color: $dark_palette_4;
        width: 100%;
        box-sizing: border-box;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        .qr_code_image {
          width: 131px;
          height: 131px;
        }
      }
    }

    .code_field {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .wallet_address {
        color: $placeholder_color;
        font-size: 16px;
        line-height: 24px;
      }

      .copy_code_field {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 11px 16px;
        gap: 8px;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid $dark_palette_6;
        border-radius: 6px;

        .hash_code {
          font-size: 16px;
          line-height: 24px;
          color: $placeholder_color;
          width: 88%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .copy_icon {
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
      }
    }

    .withdraw_field {
      display: flex;
      gap: 6px;
      align-items: center;
      width: fit-content;
      cursor: pointer;
      padding: 4px 8px;
      border-radius: 8px;

      &:hover {
        background-color: $dark_palette_5;
      }

      .withdraw_image {
        width: 24px;
        height: 24px;
      }

      .withdraw {
        font-size: 14px;
        line-height: 22px;
        color: $white;
        text-decoration: underline;
      }
    }
  }

  .show_currency_info {
    display: flex;
  }

  .hide_currency_info {
    display: none;
  }
}
